import React, { useState, useEffect } from 'react';
import Main from '../../components/Main/Main'
import { Helmet } from 'react-helmet';

const Donate = () => {

    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        // Set a timeout to delay the visibility to allow for the initial render
        const timeoutId = setTimeout(() => {
            setIsVisible(true);
        }, 100);

        // Clear the timeout on component unmount
        return () => clearTimeout(timeoutId);
    }, []);

    return (
        <>
            <Helmet>
                {/* Add the external script to the head */}
                <script type="text/javascript" src="https://donorbox.org/widget.js" />
            </Helmet>

            <Main>
                <div className="flex justify-center items-center w-full mt-8">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div className="pt-0 md:pt-10">
                            <div className="flex md:hidden justify-center items-center">
                                <div className={`header-y ${isVisible ? 'visible-y' : ''}`}>                                    
                                    <div className="flex justify-center items-center backdrop-opacity-40 backdrop-invert-0 bg-white/30 p-6">
                                        <img src="./images/YardSign.png" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="hidden md:flex md:justify-center md:items-center">
                                <div className={`header-x ${isVisible ? 'visible-x' : ''}`}>
                                    <div className="text-center">
                                        <h1 className="text-7xl text-white font-outline-2 drop-shadow-[0_3px_3px_rgba(255,49,49,0.8)]">RE-ELECT</h1><br />
                                    </div>
                                    <div className="flex justify-center items-center backdrop-opacity-40 backdrop-invert-0 bg-white/30 border border-gray-200 border-spacing-2 p-6">
                                        <img src="./images/logolarge.png" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-4">
                            <iframe
                                src="https://donorbox.org/embed/erinne-hester-for-assessor-of-property"
                                name="donorbox"
                                seamless
                                frameBorder="0"
                                scrolling="no"
                                height="900px"
                                width="100%"
                                title="Donate"
                                style={{ maxWidth: '500px', minWidth: '310px', maxHeight: 'none !important', marginBottom: '5px' }}
                            />
                            <p>All checks can be made payable to:<br />
                                <b>Erinne Hester for Assessor</b><br />609 Idlewood Drive<br />Clarksville TN 37043</p>
                        </div>
                    </div>
                </div>
            </Main>
        </>
    );
}

export default Donate;