import React from 'react'
import Main from '../../components/Main/Main'
import Container from '../../components/Container/Container'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

function Bio() {
    return (
        <Main>
            <Container className="w-full md:w-8/12 mx-auto mb-6">
                <div className="my-6">
                    <h1 className="text-5xl font-playfair font-semibold">Erinne Hester Biography</h1>
                    <br /><hr /><br />
                    <div className="mr-6 text-start">
                        <p>Erinne Hester has served as Assessor of Property for Montgomery County for over 10 years;
                            she will have served in Montgomery County Government for 19 years this March. Erinne
                            graduated from APSU in 1999 having earned a degree in Business Administration with a
                            concentration in accounting. She began her employment with the County in 2005 as a file clerk,
                            was the first Budget Analyst for Montgomery County Government, and was appointed as the
                            Director of Accounts and Budgets in 2008, where she oversaw an annual budget in excess of
                            $400M. Erinne also served as the Director of EMS Billing from 2009-2010.</p>
                        <p>Erinne has achieved the Certified Governmental Financial Manager (CGFM) designation from
                            the Government Finance Officer's Association (GFOA) and the Certified Public Administrator
                            designation from the State of Tennessee. She is currently pursuing the Assessment
                            Administration Specialist (AAS) designation from the International Association of Assessing
                            Officers.</p>
                        <p>Education and training achievement has been a priority since Erinne took office. Currently, over
                            70% of the Assessor's Office has achieved a State of Tennessee Assessment Certification.</p>
                        <p>Erinne most recently served as the immediate Past President of the Tennessee Assessor's
                            Association which is a peer-elected board consisting of all 95 Assessors in Tennessee.</p>
                        <p>In 2022, Erinne was instrumental in the successful passage of the “Unlisted” bill. This statewide
                            legislation allows for the suppression of property owner information on the Assessor's website.
                            She also works closely with our state delegation on foreign-ownership concerns, tax-related
                            policy for Veterans, and most recently, protection for victims of the December 9 tornadoes.</p>
                        <p>The Montgomery County Assessor's office is consistently recognized by the Assessor's
                            Association. Since 2015, the office has won 11 awards at the annual Winter meeting. And
                            since the inaugural year of the Three Star Certification, the Assessor's office has won annually.
                            The Three Star Certification is awarded each year upon meeting the successful completion of
                            unqualified (clean) real and personal property audits and continuing education participation.</p>
                        <p>Erinne sincerely believes that your property is your castle, your livelihood, or your retirement.
                            That mentality is how every concern should be addressed in the Assessor's office.</p>
                        <p>Erinne has been married to her high school sweetheart for over 21 years and is Mom to two
                            successful young men and several once-stray animals. She serves in various capacities in
                            church and in the community and was most recently appointed to the Accounting Advisory
                            Board at the College of Business at Austin Peay State University. Erinne also enjoys reading,
                            very light exercise, and traveling with her family.</p>
                    </div>
                    <div className="bg-gray-100 p-4">
                        <div className="bg-white border-2 p-4 text-center mb-4">
                            <h1 className="text-3xl font-playfair font-semibold">COMMITTEES, PANELS, BOARDS</h1>
                            <h3>FOR ERINNE HESTER</h3>
                        </div>
                        <div className="grid grid-cols-1 gap-4 pl-2 text-sm md:text-base lg:grid-cols-2 xl:pl-12">
                            <div>
                                {/* <ul>
                                    <li className="mb-2 relative before:h-2 before:w-2 before:bg-black before:border-2 before:border-black before:inline-block before:mr-2 before:rounded-full"><span className="font-semibold">Tennessee Assessor's Association</span>
                                        <ul className="ml-4 list-none">
                                            <li className="mb-2 relative before:h-2 before:w-2 before:bg-transparent before:border-2 before:border-black before:inline-block before:mr-2 before:rounded-full">
                                                Immediate Past President
                                            </li>
                                        </ul>
                                    </li>
                                </ul> */}
                                <ul className="space-y-2">
                                    <li><CheckCircleIcon style={{ fontSize: 'large', color: 'green' }} className="mr-2" /><span className="font-semibold">Tennessee Assessor's Association</span>
                                        <ul className="ml-10 list-none">
                                            <li className="mb-2 relative before:h-2 before:w-2 before:bg-transparent before:border-2 before:border-black before:inline-block before:mr-2 before:rounded-full">
                                                Immediate Past President
                                            </li>
                                        </ul>
                                    </li>
                                    <li><CheckCircleIcon style={{ fontSize: 'large', color: 'green' }} className="mr-2" /><span className="font-semibold">United Way</span></li>
                                    <li><CheckCircleIcon style={{ fontSize: 'large', color: 'green' }} className="mr-2" /><span className="font-semibold">Leadership Clarksville</span></li>
                                    <li><CheckCircleIcon style={{ fontSize: 'large', color: 'green' }} className="mr-2" /><span className="font-semibold">Leadership Middle Tennessee</span></li>
                                    <li><CheckCircleIcon style={{ fontSize: 'large', color: 'green' }} className="mr-2" /><span className="font-semibold">Tennessee Government Finance Office Association</span>
                                        <ul className="ml-10 list-none">
                                            <li className="mb-2 relative before:h-2 before:w-2 before:bg-transparent before:border-2 before:border-black before:inline-block before:mr-2 before:rounded-full">
                                                Treasurer
                                            </li>
                                        </ul>
                                    </li>
                                    <li><CheckCircleIcon style={{ fontSize: 'large', color: 'green' }} className="mr-2" /><span className="font-semibold">APSU College of Business</span>
                                        <ul className="ml-10 list-none">
                                            <li className="mb-2 relative before:h-2 before:w-2 before:bg-transparent before:border-2 before:border-black before:inline-block before:mr-2 before:rounded-full">
                                                Accounting Program Advisory Board
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <ul className="space-y-2">
                                    <li><CheckCircleIcon style={{ fontSize: 'large', color: 'green' }} className="mr-2" /><span className="font-semibold">100 Women Who Care</span>
                                        <ul className="ml-10 list-none">
                                            <li className="mb-2 relative before:h-2 before:w-2 before:bg-transparent before:border-2 before:border-black before:inline-block before:mr-2 before:rounded-full">
                                                Founding Member, Clarksville Chapter
                                            </li>
                                        </ul>
                                    </li>
                                    <li><CheckCircleIcon style={{ fontSize: 'large', color: 'green' }} className="mr-2" /><span className="font-semibold">Rotary Foundation Paul Harris Fellow</span></li>
                                    <li><CheckCircleIcon style={{ fontSize: 'large', color: 'green' }} className="mr-2" /><span className="font-semibold">Custom's House Museum Guild</span></li>
                                    <li><CheckCircleIcon style={{ fontSize: 'large', color: 'green' }} className="mr-2" /><span className="font-semibold">First Baptist Church of Clarksville</span>
                                        <ul className="ml-10 list-none">                                            
                                            <li className="mb-2 relative before:h-2 before:w-2 before:bg-transparent before:border-2 before:border-black before:inline-block before:mr-2 before:rounded-full">
                                                Finance Committee
                                            </li>
                                            <li className="mb-2 relative before:h-2 before:w-2 before:bg-transparent before:border-2 before:border-black before:inline-block before:mr-2 before:rounded-full">
                                                Audit Committee
                                            </li>
                                            <li className="mb-2 relative before:h-2 before:w-2 before:bg-transparent before:border-2 before:border-black before:inline-block before:mr-2 before:rounded-full">
                                                Sunday School Teacher
                                            </li>
                                        </ul>
                                    </li>
                                    <li><CheckCircleIcon style={{ fontSize: 'large', color: 'green' }} className="mr-2" /><span className="font-semibold">Member of Montgomery County Republican Woman</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </Main>
    )
}

export default Bio
