import React from 'react';

type MainProps = {
    children: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined;
    className?: string;
  };

  export default function Main(props: MainProps) {
    const { className, children } = props;
    const mainClassName = `flex-1 border ${className}`;

    return (
        <>
            {/* wrapped entire app in flex div and set min-h-screen to apply flex-1 to main section so it can grow as needed and footer can go to bottom of page */}
            <div id="main" className={mainClassName}>
                <main>
                    {children}
                </main>
            </div>
        </>
    );
};
