import React, { useState, useEffect, useRef } from 'react'
import { NavLink } from 'react-router-dom';

function Navbar() {

    // to change burger classes
    const [isMenuOpen, setisMenuOpen] = useState(false)
    const [burger_class, setBurgerClass] = useState("burger-bar unclicked")
    const [menu_class, setMenuClass] = useState("hide")
    const refSidebar = useRef<HTMLInputElement>(null);
    const refHamburger = useRef<HTMLInputElement>(null);

    // Active NavLink Styling
    const activeStyle = {
        background: "#BC4C4C",
        textDecoration: "none",
        color: "white",
    };

    // toggle burger menu change
    const updateMenu = () => {
        if (!isMenuOpen) {
            setBurgerClass("burger-bar clicked")
            setMenuClass("show")
        }
        else {
            setBurgerClass("burger-bar unclicked")
            setMenuClass("hide")
        }
        setisMenuOpen(!isMenuOpen)
    }

    const handleClickOutside = (e: { target: any; }) => {
        if (refSidebar.current != null && refHamburger.current != null) {
            if (!refSidebar.current.contains(e.target) && !refHamburger.current.contains(e.target)) {
                setBurgerClass("burger-bar unclicked")
                setMenuClass("hide")
                setisMenuOpen(false)
            }
        }
    }

    // Handle click outside sidebar and hamburger icon to close Sidebar Menu
    useEffect(() => {
        document.addEventListener("click", handleClickOutside, true)
    }, [])

    return (
        <>
            {/* <!-- Navbar start --> */}
            <nav id="navbar" className="sticky top-0 z-40 flex flex-row max-w-full h-32 px-4 bg-white border-b border-gray-200 shadow-xl">
                <section className="flex flex-auto">

                    <div className="flex w-full justify-start items-center">
                        <img src="./images/logo.png" alt="" className="max-h-28" />
                    </div>
                    <div className="md:hidden">
                        <div id="burgerIcon" className="flex flex-col h-full w-10 mr-4 justify-center cursor-pointer" onClick={updateMenu} ref={refHamburger}>
                            <div className={burger_class}></div>
                            <div className={burger_class}></div>
                            <div className={burger_class}></div>
                        </div>
                    </div>
                </section>
                <div id="navbarlinks" className="hidden space-x-0 text-sm lg:text-base md:flex lg:space-x-6 mr-2">
                    <NavLink to="/" className="group flex items-center py-3 pl-3 pr-4 text-gray-600 hover:bg-dullBlue hover:text-white">
                        <svg className="w-4 h-4 text-gray-600 transition duration-75 hover:text-white group-hover:text-white"
                            fill="currentColor" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                            <path d="M32 18.451l-16-12.42-16 12.42v-5.064l16-12.42 16 12.42zM28 18v12h-8v-8h-8v8h-8v-12l12-9z"></path>
                        </svg>
                        <span className="select-none pl-2">Home</span>
                    </NavLink>
                    <NavLink to="about" className="group flex items-center py-3 pl-3 pr-4 text-gray-600 hover:bg-dullBlue hover:text-white">
                        <svg className="w-4 h-4 text-gray-600 transition duration-75 group-hover:text-white"
                            fill="currentColor" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14 9.5c0-0.825 0.675-1.5 1.5-1.5h1c0.825 0 1.5 0.675 1.5 1.5v1c0 0.825-0.675 1.5-1.5 1.5h-1c-0.825 0-1.5-0.675-1.5-1.5v-1z"></path>
                            <path d="M20 24h-8v-2h2v-6h-2v-2h6v8h2z"></path>
                            <path d="M16 0c-8.837 0-16 7.163-16 16s7.163 16 16 16 16-7.163 16-16-7.163-16-16-16zM16 29c-7.18 0-13-5.82-13-13s5.82-13 13-13 13 5.82 13 13-5.82 13-13 13z"></path>
                        </svg>
                        <span className="select-none pl-2">About</span>
                    </NavLink>
                    <NavLink to="bio" className="group flex items-center py-3 pl-3 pr-4 text-gray-600 hover:bg-dullBlue hover:text-white">
                        <svg className="w-4 h-4 text-gray-600 transition duration-75 group-hover:text-white"
                            fill="currentColor" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                            <path d="M27 0h-24c-1.65 0-3 1.35-3 3v26c0 1.65 1.35 3 3 3h24c1.65 0 3-1.35 3-3v-26c0-1.65-1.35-3-3-3zM26 28h-22v-24h22v24zM8 18h14v2h-14zM8 22h14v2h-14zM10 9c0-1.657 1.343-3 3-3s3 1.343 3 3c0 1.657-1.343 3-3 3s-3-1.343-3-3zM15 12h-4c-1.65 0-3 0.9-3 2v2h10v-2c0-1.1-1.35-2-3-2z"></path>
                        </svg>
                        <span className="select-none pl-2">Bio</span>
                    </NavLink>
                    <NavLink to="contact" className="group flex items-center py-3 pl-3 pr-4 text-gray-600 hover:bg-dullBlue hover:text-white">
                        <svg className="w-4 h-4 text-gray-600 transition duration-75 group-hover:text-white"
                            fill="currentColor" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15 4c-1.583 0-3.112 0.248-4.543 0.738-1.341 0.459-2.535 1.107-3.547 1.926-1.876 1.518-2.91 3.463-2.91 5.474 0 1.125 0.315 2.217 0.935 3.247 0.646 1.073 1.622 2.056 2.821 2.842 0.951 0.624 1.592 1.623 1.761 2.748 0.028 0.187 0.051 0.375 0.068 0.564 0.085-0.079 0.169-0.16 0.254-0.244 0.754-0.751 1.771-1.166 2.823-1.166 0.167 0 0.335 0.011 0.503 0.032 0.605 0.077 1.223 0.116 1.836 0.116 1.583 0 3.112-0.248 4.543-0.738 1.341-0.459 2.535-1.107 3.547-1.926 1.876-1.518 2.91-3.463 2.91-5.474s-1.033-3.956-2.91-5.474c-1.012-0.819-2.206-1.467-3.547-1.926-1.431-0.49-2.96-0.738-4.543-0.738zM15 0v0c8.284 0 15 5.435 15 12.139s-6.716 12.139-15 12.139c-0.796 0-1.576-0.051-2.339-0.147-3.222 3.209-6.943 3.785-10.661 3.869v-0.785c2.008-0.98 3.625-2.765 3.625-4.804 0-0.285-0.022-0.564-0.063-0.837-3.392-2.225-5.562-5.625-5.562-9.434 0-6.704 6.716-12.139 15-12.139zM31.125 27.209c0 1.748 1.135 3.278 2.875 4.118v0.673c-3.223-0.072-6.181-0.566-8.973-3.316-0.661 0.083-1.337 0.126-2.027 0.126-2.983 0-5.732-0.805-7.925-2.157 4.521-0.016 8.789-1.464 12.026-4.084 1.631-1.32 2.919-2.87 3.825-4.605 0.961-1.84 1.449-3.799 1.449-5.825 0-0.326-0.014-0.651-0.039-0.974 2.268 1.873 3.664 4.426 3.664 7.24 0 3.265-1.88 6.179-4.82 8.086-0.036 0.234-0.055 0.474-0.055 0.718z"></path>
                        </svg>
                        <span className="select-none pl-2">Contact</span>
                    </NavLink>
                    <NavLink to="gallery" className="group flex items-center py-3 pl-3 pr-4 text-gray-600 hover:bg-dullBlue hover:text-white">
                        <svg className="w-4 h-4 text-gray-600 transition duration-75 group-hover:text-white"
                            fill="currentColor" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.5 19c0 3.59 2.91 6.5 6.5 6.5s6.5-2.91 6.5-6.5-2.91-6.5-6.5-6.5-6.5 2.91-6.5 6.5zM30 8h-7c-0.5-2-1-4-3-4h-8c-2 0-2.5 2-3 4h-7c-1.1 0-2 0.9-2 2v18c0 1.1 0.9 2 2 2h28c1.1 0 2-0.9 2-2v-18c0-1.1-0.9-2-2-2zM16 27.875c-4.902 0-8.875-3.973-8.875-8.875s3.973-8.875 8.875-8.875c4.902 0 8.875 3.973 8.875 8.875s-3.973 8.875-8.875 8.875zM30 14h-4v-2h4v2z"></path>
                        </svg>
                        <span className="select-none pl-2">Gallery</span>
                    </NavLink>
                    <a href="https://www.facebook.com/ErinneHesterAssessor" target="_blank" rel="noopener noreferrer" className="group flex items-center py-3 pl-3 pr-4">
                        <img src="/images/socials/facebook.svg" alt="" className="w-6 h-6" />
                    </a>
                    <NavLink to="donate" className="group flex items-center py-3 pl-3 pr-4 bg-dullRed text-gray-200 hover:bg-electionRed hover:text-white transition duration-300 ">
                        <svg className="w-4 h-4 text-white transition duration-75 group-hover:text-white"
                            fill="currentColor" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                            <path d="M29 4h-26c-1.65 0-3 1.35-3 3v18c0 1.65 1.35 3 3 3h26c1.65 0 3-1.35 3-3v-18c0-1.65-1.35-3-3-3zM3 6h26c0.542 0 1 0.458 1 1v3h-28v-3c0-0.542 0.458-1 1-1zM29 26h-26c-0.542 0-1-0.458-1-1v-9h28v9c0 0.542-0.458 1-1 1zM4 20h2v4h-2zM8 20h2v4h-2zM12 20h2v4h-2z"></path>
                        </svg>
                        <span className="select-none pl-2">Donate</span>
                    </NavLink>
                </div>
            </nav >
            {/* <!-- Navbar end --> */}

            {/* <!-- Sidebar start--> */}
            <div id="containerSidebar" className="z-40 h-full md:hidden">
                <div className="navbar-menu relative z-40 h-full">
                    <nav id="sidebar" className={`fixed flex flex-col left-0 h-full w-3/4 -translate-x-full overflow-y-auto bg-white pt-6 pb-8 sm:max-w-xs lg:w-80 ${menu_class}`} ref={refSidebar}>
                        <ul className="mb-3 text-sm font-medium">
                            <li className="nav-link">
                                <NavLink to="/" onClick={updateMenu} style={({ isActive }) => isActive ? activeStyle : undefined} className="group flex items-center py-3 pl-3 pr-4 text-gray-600 hover:bg-dullBlue hover:text-white">
                                    <svg className="w-4 h-4 text-slate-600 transition duration-75 hover:text-white group-hover:text-white"
                                        fill="currentColor" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M32 18.451l-16-12.42-16 12.42v-5.064l16-12.42 16 12.42zM28 18v12h-8v-8h-8v8h-8v-12l12-9z"></path>
                                    </svg>
                                    <span className="select-none pl-2">Home</span>
                                </NavLink>
                            </li>
                            <li className="nav-link">
                                <NavLink to="about" onClick={updateMenu} style={({ isActive }) => isActive ? activeStyle : undefined} className="group flex items-center py-3 pl-3 pr-4 text-gray-600 hover:bg-dullBlue hover:text-white">
                                    <svg className="w-4 h-4 text-slate-600 transition duration-75 hover:text-white group-hover:text-white"
                                        fill="currentColor" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14 9.5c0-0.825 0.675-1.5 1.5-1.5h1c0.825 0 1.5 0.675 1.5 1.5v1c0 0.825-0.675 1.5-1.5 1.5h-1c-0.825 0-1.5-0.675-1.5-1.5v-1z"></path>
                                        <path d="M20 24h-8v-2h2v-6h-2v-2h6v8h2z"></path>
                                        <path d="M16 0c-8.837 0-16 7.163-16 16s7.163 16 16 16 16-7.163 16-16-7.163-16-16-16zM16 29c-7.18 0-13-5.82-13-13s5.82-13 13-13 13 5.82 13 13-5.82 13-13 13z"></path>
                                    </svg>
                                    <span className="select-none pl-2">About</span>
                                </NavLink>
                            </li>
                            <li className="nav-link">
                                <NavLink to="bio" onClick={updateMenu} style={({ isActive }) => isActive ? activeStyle : undefined} className="group flex items-center py-3 pl-3 pr-4 text-gray-600 hover:bg-dullBlue hover:text-white">
                                    <svg className="w-4 h-4 text-slate-600 transition duration-75 hover:text-white group-hover:text-white"
                                        fill="currentColor" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M27 0h-24c-1.65 0-3 1.35-3 3v26c0 1.65 1.35 3 3 3h24c1.65 0 3-1.35 3-3v-26c0-1.65-1.35-3-3-3zM26 28h-22v-24h22v24zM8 18h14v2h-14zM8 22h14v2h-14zM10 9c0-1.657 1.343-3 3-3s3 1.343 3 3c0 1.657-1.343 3-3 3s-3-1.343-3-3zM15 12h-4c-1.65 0-3 0.9-3 2v2h10v-2c0-1.1-1.35-2-3-2z"></path>
                                    </svg>
                                    <span className="select-none pl-2">Bio</span>
                                </NavLink>
                            </li>
                            <li className="nav-link">
                                <NavLink to="contact" onClick={updateMenu} style={({ isActive }) => isActive ? activeStyle : undefined} className="group flex items-center py-3 pl-3 pr-4 text-gray-600 hover:bg-dullBlue hover:text-white">
                                    <svg className="w-4 h-4 text-slate-600 transition duration-75 hover:text-white group-hover:text-white"
                                        fill="currentColor" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15 4c-1.583 0-3.112 0.248-4.543 0.738-1.341 0.459-2.535 1.107-3.547 1.926-1.876 1.518-2.91 3.463-2.91 5.474 0 1.125 0.315 2.217 0.935 3.247 0.646 1.073 1.622 2.056 2.821 2.842 0.951 0.624 1.592 1.623 1.761 2.748 0.028 0.187 0.051 0.375 0.068 0.564 0.085-0.079 0.169-0.16 0.254-0.244 0.754-0.751 1.771-1.166 2.823-1.166 0.167 0 0.335 0.011 0.503 0.032 0.605 0.077 1.223 0.116 1.836 0.116 1.583 0 3.112-0.248 4.543-0.738 1.341-0.459 2.535-1.107 3.547-1.926 1.876-1.518 2.91-3.463 2.91-5.474s-1.033-3.956-2.91-5.474c-1.012-0.819-2.206-1.467-3.547-1.926-1.431-0.49-2.96-0.738-4.543-0.738zM15 0v0c8.284 0 15 5.435 15 12.139s-6.716 12.139-15 12.139c-0.796 0-1.576-0.051-2.339-0.147-3.222 3.209-6.943 3.785-10.661 3.869v-0.785c2.008-0.98 3.625-2.765 3.625-4.804 0-0.285-0.022-0.564-0.063-0.837-3.392-2.225-5.562-5.625-5.562-9.434 0-6.704 6.716-12.139 15-12.139zM31.125 27.209c0 1.748 1.135 3.278 2.875 4.118v0.673c-3.223-0.072-6.181-0.566-8.973-3.316-0.661 0.083-1.337 0.126-2.027 0.126-2.983 0-5.732-0.805-7.925-2.157 4.521-0.016 8.789-1.464 12.026-4.084 1.631-1.32 2.919-2.87 3.825-4.605 0.961-1.84 1.449-3.799 1.449-5.825 0-0.326-0.014-0.651-0.039-0.974 2.268 1.873 3.664 4.426 3.664 7.24 0 3.265-1.88 6.179-4.82 8.086-0.036 0.234-0.055 0.474-0.055 0.718z"></path>
                                    </svg>
                                    <span className="select-none pl-2">Contact</span>
                                </NavLink>
                            </li>
                            <li className="nav-link">
                                <NavLink to="gallery" onClick={updateMenu} style={({ isActive }) => isActive ? activeStyle : undefined} className="group flex items-center py-3 pl-3 pr-4 text-gray-600 hover:bg-dullBlue hover:text-white">
                                    <svg className="w-4 h-4 text-slate-600 transition duration-75 hover:text-white group-hover:text-white"
                                        fill="currentColor" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.5 19c0 3.59 2.91 6.5 6.5 6.5s6.5-2.91 6.5-6.5-2.91-6.5-6.5-6.5-6.5 2.91-6.5 6.5zM30 8h-7c-0.5-2-1-4-3-4h-8c-2 0-2.5 2-3 4h-7c-1.1 0-2 0.9-2 2v18c0 1.1 0.9 2 2 2h28c1.1 0 2-0.9 2-2v-18c0-1.1-0.9-2-2-2zM16 27.875c-4.902 0-8.875-3.973-8.875-8.875s3.973-8.875 8.875-8.875c4.902 0 8.875 3.973 8.875 8.875s-3.973 8.875-8.875 8.875zM30 14h-4v-2h4v2z"></path>
                                    </svg>
                                    <span className="select-none pl-2">Gallery</span>
                                </NavLink>
                            </li>
                            <li className="nav-link">
                                <NavLink to="donate" onClick={updateMenu} style={({ isActive }) => isActive ? activeStyle : undefined} className="group flex items-center py-3 pl-3 pr-4 text-slate-700 hover:bg-dullBlue hover:text-white">
                                    <svg className="w-4 h-4 text-slate-500 transition duration-75 hover:text-white group-hover:text-white"
                                        fill="currentColor" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M29 4h-26c-1.65 0-3 1.35-3 3v18c0 1.65 1.35 3 3 3h26c1.65 0 3-1.35 3-3v-18c0-1.65-1.35-3-3-3zM3 6h26c0.542 0 1 0.458 1 1v3h-28v-3c0-0.542 0.458-1 1-1zM29 26h-26c-0.542 0-1-0.458-1-1v-9h28v9c0 0.542-0.458 1-1 1zM4 20h2v4h-2zM8 20h2v4h-2zM12 20h2v4h-2z"></path>
                                    </svg>
                                    <span className="select-none pl-2">Donate</span>
                                </NavLink>
                            </li>
                            <hr />
                            <li className="nav-link">
                                <a href="https://www.facebook.com/ErinneHesterAssessor" target="_blank" rel="noopener noreferrer" className="group flex items-center py-3 pl-3 pr-4">
                                    <img src="/images/socials/facebook.svg" alt="" className="w-6 h-6" />
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div className="mx-auto lg:ml-80"></div>
            </div>
            {/* <!-- Sidebar end --> */}
        </>
    );
};

export default Navbar;