import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { useForm } from "react-hook-form";
import Main from '../../components/Main/Main'
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Alert } from "@mui/material";
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

//schema for zod validation
const schema = z.object({
  yourName: z.string().min(1, { message: 'Name is required' }),
  email: z.string().min(1, { message: 'Email required' }).email("Invalid email address"),
  message: z.string().min(1, { message: 'Please input your message so that I may better serve you.' }),
}).strict();

//infer field types from zod schema
type FormSchemaType = z.infer<typeof schema>;

function Contact() {

  //Use state for Material UI alert
  const [alert, setAlert] = useState(false);
  const [issue, setIssueAlert] = useState(false);

  const { register, handleSubmit, reset, formState, formState: { errors }, } = useForm<FormSchemaType>({
    resolver: zodResolver(schema),
    defaultValues: {
      yourName: "",
      email: "",
      message: ""
    }
  });

  //form submission action
  const onSubmit = handleSubmit(async (data: FormSchemaType) => {

    if (data.yourName && data.email && data.message) {
      axios.post('https://j2eti5zm8c.execute-api.us-east-1.amazonaws.com/production/sendEmail', {
        name: data.yourName,
        email: data.email,
        message: data.message,
      })
        .then(response => {
          console.log('Email sent:', response.data);
          // Email sent successfully
          setAlert(true);
        })
        .catch(error => {
          console.error('Error sending email:', error);
          setIssueAlert(true);
        });
    };
    return;
  });

  //clear form elements on successful submission
  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset({
        message: ""
      });
    }
  }, [formState, reset]);

  //auto hide success alert message for form submittal
  useEffect(() => {
    setTimeout(() => {
      setAlert(false);
    }, 7000);
  }, [alert])
  //auto hide error alert message for form submittal
  useEffect(() => {
    setTimeout(() => {
      setIssueAlert(false);
    }, 7000);
  }, [issue])

  return (
    <Main>
      <div className="flex justify-center items-center w-full">
        <div className="bg-white p-6 rounded-lg shadow-md w-11/12 mt-6">
        <h1 className="text-5xl font-playfair font-semibold">Contact Erinne</h1>
        <br /><hr /><br />
          <form onSubmit={onSubmit}>
            <div className="mb-4">
              <label htmlFor="name" className="block text-gray-600 font-semibold">
                Name
              </label>
              <input
                type="text"
                {...register("yourName")}
                id="yourName"
                name="yourName"
                placeholder="Your Name"
                className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-electionBlue"
                required
              />
              {errors.yourName && (
                <span className="text-red-800 block mt-2">
                  {errors.yourName?.message}
                </span>
              )}
            </div>

            <div className="mb-4">
              <label htmlFor="email" className="block text-gray-600 font-semibold">
                Email
              </label>
              <input
                type="email"
                {...register("email")}
                id="email"
                name="email"
                placeholder="Your Email"
                className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-electionBlue"
                required
              />
              {errors.email && (
                <span className="text-red-800 block mt-2">
                  {errors.email?.message}
                </span>
              )}
            </div>

            <div className="mb-4">
              <label htmlFor="message" className="block text-gray-600 font-semibold">
                Message
              </label>
              <textarea
                id="message"
                {...register("message")}
                name="message"
                placeholder="Your Message"
                className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-electionBlue"
                rows={6}
                required
              ></textarea>
              {errors.message && (
                <span className="text-red-800 block mt-2">
                  {errors.message?.message}
                </span>
              )}
            </div>

            <button
              type="submit"
              className="bg-electionBlue text-white py-2 mb-2 px-4 rounded hover:bg-slate-700 transition duration-300"
            >
              Send
            </button>

            {/* alert messages */}
            <Box sx={{ width: '90%' }}>
              <Collapse in={issue}>
                <Alert
                  severity="error"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setIssueAlert(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                  sx={{ mb: 2 }}
                ><AlertTitle>Error</AlertTitle>
                  There was an error submitting your message.
                </Alert>
              </Collapse>
            </Box>

            <Box sx={{ width: '90%' }}>
              <Collapse in={alert}>
                <Alert
                  severity="success"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setAlert(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                  sx={{ mb: 2 }}
                ><AlertTitle>Success</AlertTitle>
                  Thank you for reaching out, your message has been submitted successfully!
                </Alert>
              </Collapse>
            </Box>
          </form>
        </div>
      </div>
    </Main>
  );
}

export default Contact
