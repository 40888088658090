import React, { useRef } from 'react';
import imageUrls from './images';

const ImageScroller = () => {
    const scrollerRef = useRef<HTMLDivElement | null>(null);
    const scrollStep = 200; // Adjust the scroll step as needed
    // const visibleImages = 5; // Number of visible images at a time
    // const imageWidth = 200; // Width for each image (adjust as needed)

    const scrollLeft = () => {
        if (scrollerRef.current) {
            scrollerRef.current.scrollLeft -= scrollStep;
        }
    };

    const scrollRight = () => {
        if (scrollerRef.current) {
            scrollerRef.current.scrollLeft += scrollStep;
        }
    };

    return (
        <div className="w-11/12 mx-auto relative overflow-hidden pt-6">
            <div ref={scrollerRef} className="flex overflow-hidden relative w-9/12 xl:w-11/12 mx-auto py-4 border border-r border-slate-300">
                {imageUrls && imageUrls.length > 0 && (
                    <div className="flex space-x-16">
                        {imageUrls.map((imageUrl, index) => (
                            <div key={index} className="flex-shrink-0 h-80 w-80">
                                <div style={{ paddingBottom: '100%', position: 'relative' }}>
                                    <div className="absolute inset-0">
                                        <div className="w-full h-full bg-transparent pointer-events-none"></div>
                                    </div>
                                    <img
                                        src={imageUrl}
                                        alt={`Scroller item ${index + 1}`}
                                        className="absolute inset-0 w-full h-full border border-slate-300 bg-white p-1 object-cover pointer-events-none"
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>

            <button className="absolute left-0 top-1/2 transform -translate-y-1/2" onClick={scrollLeft}>
                <svg className="w-10 h-10 lg:w-14 lg:h-14 text-gray-400 transition duration-75 hover:text-slate-500 group-hover:text-slate-500"
                    fill="currentColor" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16 32c8.837 0 16-7.163 16-16s-7.163-16-16-16-16 7.163-16 16 7.163 16 16 16zM16 3c7.18 0 13 5.82 13 13s-5.82 13-13 13-13-5.82-13-13 5.82-13 13-13z"></path>
                    <path d="M20.914 9.914l-2.829-2.829-8.914 8.914 8.914 8.914 2.828-2.828-6.086-6.086z"></path>
                </svg>
            </button>

            <button className="absolute right-0 top-1/2 transform -translate-y-1/2" onClick={scrollRight}>
                <svg className="w-10 h-10 lg:w-14 lg:h-14 text-gray-400 transition duration-75 hover:text-slate-500 group-hover:text-slate-500"
                    fill="currentColor" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16 0c-8.837 0-16 7.163-16 16s7.163 16 16 16 16-7.163 16-16-7.163-16-16-16zM16 29c-7.18 0-13-5.82-13-13s5.82-13 13-13 13 5.82 13 13-5.82 13-13 13z"></path>
                    <path d="M11.086 22.086l2.829 2.829 8.914-8.914-8.914-8.914-2.828 2.828 6.086 6.086z"></path>
                </svg>
            </button>
        </div>
    );
};

export default ImageScroller;
