import React from 'react'
import Main from '../../components/Main/Main'
import Container from '../../components/Container/Container'

const About = () => {
  return (
    <Main>
      <Container className="w-full md:w-8/12 mx-auto mb-6">
        <div className="my-6">
          <h1 className="text-5xl font-playfair font-semibold">Meet<span className="md:hidden"><br /></span> Erinne Hester</h1>
          <br /><hr /><br />
          <img src="images/ErinneHesterCrop.jpg" className="rounded-full float-left h-36 mr-4 mb-1 md:ml-4 md:float-right md:h-48 lg:h-80" alt="" /> 
          <div className="mr-6 text-start">
            <p>Erinne Hester, incumbent Montgomery County Assessor of Property, announces her campaign for re-election aiming to continue her service and dedication to the residents of Montgomery County.</p>
            <p>Hester, who has served as Assessor since 2013, brings a wealth of experience and expertise to the position.</p>
            <p>Under Hester’s leadership, the Assessor’s office has employed a multi-tiered strategy to provide common sense solutions, from education to technology, to better serve their wide customer base.
              Hester implemented an assessment education policy for all employees, which includes classes that are comprised of both State of Tennessee specific, and International Association of Assessing Officers (IAAO) curriculum.
              All employees must achieve a minimum State Board of Equalization certification, which can take more than three years.  Currently, more than half the team have completed the minimum certification and six employees are candidates
              for an IAAO professional designation, which is recognized in every jurisdiction with a mass appraisal function.</p>
            <p>Another focus has been to expand the website to provide resources for the public.  Tools and applications added under Hester include: Stratum, Community, online address maintenance, proration and tax calculators, and GIS shapefiles.
              In January 2020, the Assessor’s office implemented online Tangible Personal Property filing, allowing the office to maintain twice the number of accounts while keeping staff size the same.  The Assessor’s website receives an average of
              100,000 hits each month.</p>
            <p>Hester and her team have received multiple awards over the last decade to include the annual Three Star Certification. Hester has been awarded Board Member of the Year (2015) and Assessor of the Year (2016).  The entire office was awarded
              Excellence in Operations (2017 and 2023) for implementing policies & standards that have been adopted by multiple assessment offices across the state.  Lastly, Derek Flanigan, Chief Deputy, was awarded Chief Deputy of the Year (2017) and Chief Deputy of the Year for Middle Tennessee Region (2023). The office has
              earned clean audits from the Comptroller of the Treasury in both real property and tangible personal property each year under Hester’s leadership.</p>
            <p>As President of the Tennessee Assessor’s Association in 2022, Erinne worked with the state delegation to pass the “Unlisted” bill, which allows property owners to suppress the ownership of their primary residence on all Assessor online property search functions.</p>
            <p>Prior to being elected Assessor, Hester served as the Montgomery County Director of Accounts and Budgets from 2008-2013, where she earned the Certified Governmental Accounting Manager designation.</p>
            <p>Hester has a degree in Accounting from APSU; serves as Immediate Past President of the Tennessee Assessor’s Association; serves on the United Way Budgets and Allocations committee; was recently invited to serve on the Accounting Program Advisory
              Board of the APSU College of Business; is a graduate of Leadership Clarksville and Leadership Middle Tennessee; served on the Custom’s House Museum Guild; a founding member of the Clarksville-Montgomery County 100 Women Who Care giving circle;
              and serves on several committees at her church in addition to teaching middle school Sunday School.  Erinne has been married to Bryce Hester for over 21 years and they have two sons, Hayden and Ethan.</p>
          </div>
          <div>
            <img src="images/Awards.jpg" alt="Awards" />
          </div>
        </div>        
      </Container>
    </Main>
  )
}

export default About
