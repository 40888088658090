import React from 'react'
import { useNavigate } from 'react-router-dom';
import Main from '../../components/Main/Main'

const NotFound = () => {
    const navigate = useNavigate();

    const handleRedirectHome = () => {
        navigate('/');
    };

    const handleRedirectContact = () => {
        navigate('/contact')
    }

    return (
        <Main className="bg-red-500">
            <section className="flex flex-col mx-auto mt-2 mb-6 md:flex-row md:mt-10 xl:mt-14 bg-red-500">
    <div className="w-full pl-4 pr-2 md:px-0 mx-auto text-center text-white">
        <h1 className="text-8xl mb-12">404</h1>
        <p>The page you were attempting to reach cannot be located.</p>
        <div className="mt-10 space-y-5 md:space-x-5 md:space-y-0 md:flex md:justify-center">
            <button className="font-poppins py-2 w-full md:w-auto px-4 md:px-6 rounded-3xl bg-white text-black hover:bg-slate-700 hover:text-white transition-colors duration-300 focus:outline-none ring-blue-800 ring-offset-2 ring-2 focus:ring-opacity-50 drop-shadow-md" onClick={handleRedirectHome}>Visit Homepage</button>
            <button className="font-poppins py-2 w-full md:w-auto px-4 md:px-6 mt-3 md:mt-0 rounded-3xl bg-white text-black hover:bg-slate-700 hover:text-white transition-colors duration-300 focus:outline-none ring-blue-800 ring-offset-2 ring-2 focus:ring-opacity-50 drop-shadow-md" onClick={handleRedirectContact}>Contact Us</button>
        </div>
    </div>
</section>

        </Main>
    )
}

export default NotFound;
