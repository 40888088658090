import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './index.css';

// Components
import Navbar from './components/Navbar/Navbar'
import Footer from './components/Footer/Footer';

// General views
import Home from './views/Home/index';
import Contact from './views/Contact/Contact';
import About from './views/About/About';
import Bio from './views/Bio/Bio'
import Gallery from './views/Gallery/Gallery';
import Donate from './views/Donate/Donate';
import Volunteer from './views/Sheet/Volunteer';
import Results from './views/Sheet/Results';
import NotFound from './views/NotFound/NotFound'

function App() {
  return (
    <div>
      <div className="flex flex-col min-h-screen bg-pageBG">
        <Router>
          <Navbar />
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/about' element={<About />} />
            <Route path='/bio' element={<Bio />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/gallery' element={<Gallery />} />
            <Route path='/donate' element={<Donate />} />
            <Route path='/volunteer' element={<Volunteer />} />
            <Route path='/results' element={<Results />} />
            <Route path='/*' element={<NotFound />} />
          </Routes>
          <Footer />
        </Router>
      </div>
    </div>
  );
}

export default App;