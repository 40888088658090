import React from 'react';

function Footer() {
    return (
        <>
            {/* apply sticky top-[100vh] to footer so footer is always at the bottom of the page */}
            <footer id="footer" className="sticky top-[100vh] w-full bg-electionRed border-t border-gray-200">
                <div className="flex p-4 mt-2 justify-center items-center">
                    <span className="text-sm text-white text-center">Paid for by the Campaign to Re-Elect Erinne Hester, Assessor of Property | Betty Burchett, Treasurer</span>
                </div>
            </footer>
        </>
    );
};

export default Footer;