import React from 'react'

function Volunteer() {
  return (
    <>
      <div className="p-4 md:hidden">
        <p>It appears you are on a mobile device.</p>
        <p>Please click&nbsp;
        <a href="https://docs.google.com/spreadsheets/d/10w_-6ZG06dEwZr9ZfrfcD4vjlkRpn7ueA3vY2OnGL04/edit?usp=sharing" target="_blank" rel="noreferrer" className="underline text-blue-800">here</a>
        &nbsp;to open a mobile friendly version of the volunteer sheet.
        </p>
      </div>

      <div className="hidden md:flex md:m-2">
        <iframe title="volunteersheet" style={{ border: 'none', overflow: 'auto' }} width="98%" height="1000px" src="https://docs.google.com/spreadsheets/d/10w_-6ZG06dEwZr9ZfrfcD4vjlkRpn7ueA3vY2OnGL04/edit?usp=sharing?widget=true&amp;headers=false&amp;"></iframe>
      </div>
    </>
  )
}

export default Volunteer