import React from 'react'

function Container(props: { children: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; className?: string }) {
  const { className = '' } = props;

  return (
    <div className={`flex flex-col justify-start bg-white outline-none shadow-xl p-6 text-slate-700 ${className}`}>
      {props.children}
    </div>
  )
}

export default Container