import React from 'react'
import Main from '../../components/Main/Main'
import ImageScroller from '../../components/ImageScroller/ImageScroller';
import politicalBackground from '../../images/bgbricks.jpg';

function Home() {

  const backgroundStyle = {
    backgroundImage: `url(${politicalBackground})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    opacity: 0.7, // Adjust the opacity as needed
    blur: '4px',
  };

  return (
    <>
      <Main className='border'>
        <div className="hidden xl:flex relative">
          <div className="absolute top-0 left-0 right-0 bottom-0 blur-sm" style={backgroundStyle}></div>
          <div className="relative w-full">
            <div className="grid grid-cols-3 w-11/12 mx-auto gap-4">
              <div className="flex bg-electionBlue/50 p-4 shadow-xl justify-center items-center">
                {/* <div className="flex justify-center items-center">
                  <svg className="w-16 h-16 text-white"
                    fill="currentColor" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                    <path d="M32 12.408l-11.056-1.607-4.944-10.018-4.944 10.018-11.056 1.607 8 7.798-1.889 11.011 9.889-5.199 9.889 5.199-1.889-11.011 8-7.798zM16 23.547l-6.983 3.671 1.334-7.776-5.65-5.507 7.808-1.134 3.492-7.075 3.492 7.075 7.807 1.134-5.65 5.507 1.334 7.776-6.983-3.671z"></path>
                  </svg>
                </div> */}
                {/* <div className="text-center">
                  <h1 className="text-7xl text-white font-outline-2 drop-shadow-[0_3px_3px_rgba(255,49,49,0.8)]">RE-ELECT</h1><br />
                </div> */}
                <div className="flex justify-center items-center backdrop-opacity-40 backdrop-invert-0 bg-white/30 border border-gray-200 border-spacing-2 p-6">
                  <img src="./images/YardSign.png" alt="" />
                </div>
                {/* <div className="flex justify-center items-center mt-4 space-x-6">
                  <svg className="w-16 h-16 text-white"
                    fill="currentColor" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                    <path d="M32 12.408l-11.056-1.607-4.944-10.018-4.944 10.018-11.056 1.607 8 7.798-1.889 11.011 9.889-5.199 9.889 5.199-1.889-11.011 8-7.798zM16 23.547l-6.983 3.671 1.334-7.776-5.65-5.507 7.808-1.134 3.492-7.075 3.492 7.075 7.807 1.134-5.65 5.507 1.334 7.776-6.983-3.671z"></path>
                  </svg>
                  <svg className="w-16 h-16 text-white"
                    fill="currentColor" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                    <path d="M32 12.408l-11.056-1.607-4.944-10.018-4.944 10.018-11.056 1.607 8 7.798-1.889 11.011 9.889-5.199 9.889 5.199-1.889-11.011 8-7.798zM16 23.547l-6.983 3.671 1.334-7.776-5.65-5.507 7.808-1.134 3.492-7.075 3.492 7.075 7.807 1.134-5.65 5.507 1.334 7.776-6.983-3.671z"></path>
                  </svg>
                  <svg className="w-16 h-16 text-white"
                    fill="currentColor" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                    <path d="M32 12.408l-11.056-1.607-4.944-10.018-4.944 10.018-11.056 1.607 8 7.798-1.889 11.011 9.889-5.199 9.889 5.199-1.889-11.011 8-7.798zM16 23.547l-6.983 3.671 1.334-7.776-5.65-5.507 7.808-1.134 3.492-7.075 3.492 7.075 7.807 1.134-5.65 5.507 1.334 7.776-6.983-3.671z"></path>
                  </svg>
                </div> */}
              </div>
              <div className="flex col-span-2 p-10 shadow-xl justify-center items-center rounded-full ring ring-offset-4 ring-offset-gray-200 ring-white overflow-hidden h-[750px] my-4">
                <div className="grid grid-cols-2 gap-2">
                  <div className="mt-36 xl:-ml-12 xxl:ml-6 xxxl:ml-24">
                    <img src="images/ErinneFullPhotoCrop.png" className="h-[700px] bg-blend-luminosity" alt="" />
                  </div>
                  <div className="flex w-full justify-center items-center pr-6">
                    <blockquote className="font-playfair text-xl text-center text-slate-700 -ml-10 xl:-ml-14 xxl:text-2xl xxl:-ml-28 xxxl:text-3xl xxxl:-ml-36">
                      I am honored to have served as Montgomery County Assessor for the past ten years, and I am excited to continue working on behalf of the residents of this great community. Throughout my tenure, I have strived to bring fairness, transparency, and accountability to the assessment process. I am committed to ensuring that all residents receive accurate and equitable assessments, while providing exceptional customer service and support.
                      <br /><br />
                      I hope you'll explore the website and see for yourself why I am still the most qualified candidate to lead this office.  I ask for your vote in the March 5, 2024 Republican primary election.
                    </blockquote>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="xl:hidden h-full flex justify-center items-center">
          {/* <Container className="p-4 bg-transparent m-auto"> */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg-grid-cols-3 gap-4 h-full px-4 py-6">
            <div className="flex items-center justify-center">
              <img src="images/ErinneHesterCrop.jpg" className="rounded-full h-[400px]" alt="" />
            </div>

            <div className="flex p-4 shadow-xl justify-center items-center">
              <p className="font-playfair text-xl text-center">
                I am honored to have served as Montgomery County Assessor for the past ten years, and I am excited to continue working on behalf of the residents of this great community. Throughout my tenure, I have strived to bring fairness, transparency, and accountability to the assessment process. I am committed to ensuring that all residents receive accurate and equitable assessments, while providing exceptional customer service and support.
              <br /><br />
                I hope you'll explore the website and see for yourself why I am still the most qualified candidate to lead this office.  I ask for your vote in the March 5, 2024 Republican primary election.
              </p>
            </div>

            <div className="bg-electionBlue p-4 shadow-xl md:col-span-2 lg-col-span-1">
              <div className="flex justify-center items-center backdrop-opacity-40 backdrop-invert-0 bg-white/30 border border-gray-200 border-spacing-2 p-6">
                <img src="./images/YardSign.png" alt="" />
              </div>
            </div>
          </div>
        </div>


        <div className="flex justify-center items-center bg-white my-4 h-auto p-4 drop-shadow-md border-y-8 border-dullRed">


          <a href="https://ovr.govote.tn.gov/" target="_blank" rel="noreferrer" className="flex flex-row space-x-12 justify-center items-center md:space-x-24">
            <div className="rounded-full p-2 bg-white border-4 border-dullRed w-24">
              <img src="images/usflagrounded.png" alt="" className="rounded-full drop-shadow-md" />
            </div>

            <div className="p-2">
              <img src="images/TNVote.png" alt="" className="drop-shadow-md w-96" />
            </div>

            <div className="rounded-full p-2 bg-white border-4 border-dullRed w-24">
              <img src="images/tnflaglogo.png" alt="" className="rounded-full drop-shadow-md" />
            </div>
          </a>
        </div>


        <div className="hidden md:flex overflow-hidden mx-auto bg-white my-4 h-auto pb-4 drop-shadow-md">
          <ImageScroller />
        </div>
      </Main>
    </>
  )
}

export default Home;
