import React, { useRef } from 'react'
import Main from '../../components/Main/Main'
import Container from '../../components/Container/Container'
import imageUrls from '../../components/ImageScroller/images';

const Gallery = () => {

  const scrollerRef = useRef<HTMLDivElement | null>(null);

  return (
    <Main>
      <Container className="w-full md:w-10/12 mx-auto mb-6">
        <div className="my-6">
          <h1 className="text-5xl font-playfair font-semibold">Erinne Hester, Out and About</h1>
          <br />
          <hr />
          <br />
          <div className="w-11/12 mx-auto relative overflow-hidden pt-6">
            <div ref={scrollerRef} className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
              {imageUrls.map((imageUrl, index) => (
                <div key={index}>
                  <div style={{ paddingBottom: '100%', position: 'relative' }}>
                    <div className="absolute inset-0">
                      <div className="w-full h-full bg-transparent pointer-events-none"></div>
                    </div>
                    <img
                      src={imageUrl}
                      alt={`Gallery item ${index + 1}`}
                      className="absolute inset-0 w-full h-full border border-slate-300 bg-white p-1 object-cover pointer-events-none"
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Container>
    </Main>
  );
};

export default Gallery;