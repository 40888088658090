import React from 'react'
const pdfFile = require('../../assets/PriorElectionResults.pdf');

function Results() {
  return (    
      <div className="md:flex md:m-2">
        <iframe title="priorresults" style={{ border: 'none', overflow: 'auto' }} width="100%" height="1000px" src={pdfFile}></iframe>
      </div>
  )
}

export default Results