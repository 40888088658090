const imageUrls = [
    'images/scroller/CandlelightBall.jpg',
    'images/scroller/fallfestival.jpg',
    'images/scroller/DunbarCave.jpg',
    'images/scroller/DCSPlazaFallFestival1.jpg',
    'images/scroller/DCSPlazaFallFestival2.jpg',
    'images/scroller/DPAPresentation.jpg',
    'images/scroller/FlyingHigh2023.jpg',
    'images/scroller/ValerieHunterKelleyWalk.jpg',
    'images/scroller/UncleDavid.jpg',
    'images/scroller/AwardsDinnerDec2022.jpg',
    'images/scroller/CameraPresentation.jpg',    
    'images/scroller/ClayShoot.jpg',
    'images/scroller/CommissionAgenda.jpg',
    'images/scroller/UnionCommunityCenter.jpg',
    'images/scroller/DotsonvilleChiliSupper.jpg',
    'images/scroller/DotsonvilleSpeech.jpg',
    'images/scroller/IAAOAwards.jpg',
    'images/scroller/IAAOAwards2.jpg',
    'images/scroller/LMTBlackhawk.jpg',
    'images/scroller/Plaza.jpg',
    'images/scroller/Plaza2.jpg',
    'images/scroller/Presentation.jpg',
    'images/scroller/Presentation2.jpg',
    'images/scroller/Presentation3.jpg',
    'images/scroller/RealtorPresentation.jpg',
    'images/scroller/CryeLeike.jpg',
    'images/scroller/RepublicanPartySpeech.jpg',
    'images/scroller/Lamar.jpg',
    'images/scroller/LincolnReagan.jpg',
    'images/scroller/SwearingIn.jpg',
    'images/scroller/CSMSidneyBrown.jpg',
    'images/scroller/Christmas.jpg',
    'images/scroller/DM_CPAA.jpg',
    'images/scroller/GOP.jpg',
    'images/scroller/Kickoff1.jpg',
    'images/scroller/Kickoff2.jpg',
    'images/scroller/Kickoff3.jpg',
    'images/scroller/Kickoff4.jpg',
    'images/scroller/Kickoff5.jpg',
    'images/scroller/Kickoff6.jpg',
    'images/scroller/Kickoff7.jpg',
    'images/scroller/Kickoff8.jpg',
    'images/scroller/Kickoff9.jpg',
    'images/scroller/Kickoff10.jpg',
    'images/scroller/Kickoff11.jpg',
    'images/scroller/Kickoff12.jpg',
    'images/scroller/Kickoff13.jpg',
    'images/scroller/Kickoff14.jpg',
    'images/scroller/Kickoff15.jpg',
    'images/scroller/Kickoff16.jpg',
    'images/scroller/Kickoff17.jpg',
    'images/scroller/Kickoff18.jpg',
    'images/scroller/Kickoff19.jpg',
    'images/scroller/Kickoff20.jpg',
    'images/scroller/Kickoff21.jpg',
    'images/scroller/Kickoff22.jpg',
    'images/scroller/RealtorFriends.jpg',
    'images/scroller/RealtorFriendsKim.jpg',
    'images/scroller/RPC.jpg',    
];

export default imageUrls;